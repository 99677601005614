import "./modalFotos.css";
import React, { useState } from "react";
import FotoUpload from "../../componentes/FotoUpload/FotoUpload";
import { resizeFile } from "../../utils/functions";

export default function ModalFotos({ id }) {
  const id_novo = `${id}_fotos`;

  const [uploadFotos, setUploadFotos] = useState([]);

  const handleChangeInputFotos = async () => {

    const selectedFile = document.getElementById("selecao-arquivo").files;
    let arr = [];

    for (let img of selectedFile) {

      arr.push({
        url: URL.createObjectURL(img),
        name: img.name,
        file: img,
      });
    }

    setUploadFotos(arr);
  };


  const handleEnviarFotos = async () => {
    if (uploadFotos.length == 0) {
      return;
    }

    //   const user = getSessionStorage("user");
    //   let config = {
    //     method: "post",
    //     url:
    //       conf.url_fotos() +
    //       `/pictures/newFile/${await id_marca(marca)}?path=${diretorio}`,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: "Bearer " + user.tokenFarol,
    //     },
    //   };

    //   setLoad(true);

    var formData = new FormData();

    var contador = 0;
    let ret;

    for (let obj of uploadFotos) {


      const image = await resizeFile(obj.file, 3200);
      console.log(image)
      let nomeFoto = image.name;

      formData.append("files", image, nomeFoto);

      if (contador % 5 === 0 || contador == uploadFotos.length) {
        // config.data = formData;

        // ret = await axios.request(config);

        formData = new FormData();

      }

    }

    //   setPartQtdFotos(uploadFotos.length);
    //   setProgresso(100);

    //   toast.dismiss(idToast);
    //   if ((!ret) || ret.data.success) {
    //     toast.success(`Fotos adicionadas com sucesso!`);
    //     setProgresso(100);
    //     setModif(Math.random(9999));
    //   } else {
    //     toast.warning(`Algumas fotos não foram importadas!`);
    //   }
    //   setLoad(false);
    //   handleClose();
  };


  const btnOpen = (e) => { document.getElementById(id_novo).classList.remove('display-none') };

  const btnClose = (e) => {
    document.getElementById(id_novo).classList.add('display-none')
    let list = new DataTransfer();
    document.getElementById("selecao-arquivo").files = list.files;
    document.getElementById("selecao-arquivo").value = "";
    setUploadFotos([]);
  };

  return (
    <>
      <i class="material-icons icone-add-fotos cursor" onClick={btnOpen}>add_a_photo</i>


      <div id={id_novo} class="modal display-none">
        <div class="modal-content">
          <div class="w-100">
            <i class="material-icons cursor close2" onClick={btnClose}>close</i>
            {/* <div onClick={(e) => btnClose(e.target)} class="close">&times; </div> */}
          </div>

          <div className="box-upload-fotos">
            <label className="label-input-file" for="selecao-arquivo">
              <input
                onChange={handleChangeInputFotos}
                id="selecao-arquivo"
                type="file"
                multiple
                draggable="true"
                accept="image/*"
              />
              <spam className="box-upload-fotos-2">
                <p className="texto-upload-fotos">
                  <div>
                    <i class="material-icons icone-upload-fotos" onClick={btnOpen}>add_to_photos</i>
                  </div>
                  <strong>Clique aqui </strong> para selecionar fotos <br />
                  <p className="texto-upload-fotos-2"> formatos aceitáveis: .png, .jpg, .jpeg </p>
                </p>
              </spam>
            </label>
          </div>

          <div className="row p-15" >
            {uploadFotos &&
              uploadFotos.map((item) => {
                return (
                  <div class="col s12 m2 ">
                    <FotoUpload item={item} />
                  </div>
                );
              })
            }
          </div>


          <div className="row p-15" >
            <div className="box-btn-add-foto-modal-2 p-15">
              <a onClick={handleEnviarFotos} class="waves-effect waves-light btn">ADICIONAR FOTOS</a>
            </div>
          </div>



        </div>

      </div >
    </>
  );

}
