import React, { useState, useEffect } from 'react';
// import "./topoLancamentoEstoque.css";
import { requesAmin, resizeFile } from "../../utils/functions";
import { toast } from 'react-toastify';

export default function TopoLancamentoEstoque() {
    const [atributos, setAtributos] = useState( false);
    const [fotoSelecionada, setFotoSelecionada] = useState(false);
    const [modif, setModif] = useState(false);
    const el_file = document.getElementById("selecao-arquivo");

    useEffect(() => {
        if (!atributos) {
            requesAmin("/admin/produto/listagem/atributos", "GET").then((res) => {
                setAtributos(res.data.atributos)
            }).catch((err) => { console.log(err) })
        }

        // setFotoSelecionada(false)
    }, [modif]);


    const inserirPreco = async (e) => {
        try {

            const produto = (document.getElementById('produto')).value;
            const tipo_tecido = (document.getElementById('tipo_tecido')).value;
            const cor = (document.getElementById('cores')).value;
            const preco = (document.getElementById('preco')).value;
            const desconto = (document.getElementById('desconto')).value;

            if (!produto || !tipo_tecido || !cor || !fotoSelecionada) {
                toast.warning("Preencha todos os campos")
                return;
            }

            var formdata = new FormData();
            formdata.append("produto", produto);
            formdata.append("tipo_tecido", tipo_tecido);
            formdata.append("cor", cor);
            formdata.append("preco", preco);
            formdata.append("desconto", desconto);

            const selectedFile = fotoSelecionada;
            const image = await resizeFile(selectedFile[0], 3200);
            formdata.append("file", image);

            const idToast = toast.loading("Aguarde um pouco...")
            requesAmin("/admin/produto/cadastrar/preco", "POST", formdata, false).then((value) => {
                toast.dismiss(idToast);
                toast.success("Produto cadastrado");
                setModif(Math.random(9999))
            })

        } catch (error) { console.log("função inserir : ", error.message); }

    }



    const selFoto = async (e) => {
        setFotoSelecionada(el_file.files);
    }

    return (

        <div className="row box-lancamento-estoque" >
            <div className="col s12 m10">

                <div className=" col s12 m6 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='produto'>
                        <option value={''} selected disabled>Produto</option>
                        {atributos.produtos && atributos.produtos.map((item) => { return <option value={item.id}>{item.nome} </option> })}
                    </select>
                </div>


                <div className=" col s12 m6 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='tipo_tecido'>
                        <option value={''} selected disabled>Tipo Tecido</option>
                        {atributos.tipo_tecido && atributos.tipo_tecido.map((item) => { return <option value={item.id}>{item.tipo_tecido} - {item.descricao}</option> })}
                    </select>
                </div>

                <div className=" col s12 m4 m-10-lancamento-estoque">
                    <select className='no-autoinit select-formatado' id='cores'>
                        <option value={''} selected disabled >Cores</option>
                        {atributos && atributos.cores.map((item) => { return <option value={item.id}>{item.cor}</option> })}
                    </select>
                </div>

                <div className=" input-field col s6 m4 m-10-lancamento-estoque">
                    <input id="preco" type="number" className="validate" />
                    <label for="preco">Preco</label>
                </div>
                <div className=" input-field col s6 m4 m-10-lancamento-estoque">
                    <input id="desconto" type="number" className="validate" />
                    <label for="desconto">Desconto</label>
                </div>

            </div>
            <div className="col s12 m2 text-center">

                {!fotoSelecionada ? (
                    <div className="box-upload-fotos">
                        <label className="label-input-file" for='selecao-arquivo'>
                            <input onChange={selFoto} id='selecao-arquivo' type='file' multiple draggable="true" accept="image/*" />
                            <spam className="box-upload-fotos-2">
                                <p className="texto-upload-fotos"> Clique aqui para selecionar fotos<br />
                                    <p className="texto-upload-fotos-2"> formatos aceitáveis: .png, .jpg, .jpeg </p>
                                </p>
                            </spam>
                        </label>
                    </div>) : (<img src={URL.createObjectURL(fotoSelecionada[0])} className="img-foto-upload materialboxed" />)}


            </div>

            <div className="row col s12">
                <div className="input-field  col s10">
                    <a className="waves-effect waves-light btn w-100" onClick={inserirPreco}>Adicionar</a>
                </div>
                <div className="input-field  col s2">
                    <a className="waves-effect waves-light btn w-100" onClick={() => window.location.href = ''}>Atualizar</a>
                </div>
            </div>

        </div >

    );
};
