import React, { useState, useEffect } from 'react';
import "./estoque.css";
import TituloSeparacao from "../../../componentes/tituloSeparacao/tituloSeparacao";
import MenuLateral from "../../../componentes/menuLateral/menuLateral";
import { requesAmin } from "../../../utils/functions";
import TopoEstoque from "../../../componentes/topoEstoque/topoEstoque";
import ModalZoomImage from "../../../componentes/ModalZoomImage/index";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


export default function Estoque() {
    const [load, setLoad] = useState(true);
    const [estoque, setEstoque] = useState(false);
    const [modif, setModif] = useState(true);

    useEffect(() => {
        requesAmin("/admin/produto/estoque/consultar", "GET").then((res) => {
            setEstoque(res.data.dados)
            setLoad(false)

        }).catch((err) => { console.log(err) })
    }, [modif]);

    const inserirEstoque = (e) => {

        let produto = document.getElementById('produto');
        let tipo_tecido = document.getElementById('tipo_tecido');
        let tamanhos = document.getElementById('tamanhos');
        let cor = document.getElementById('cores');
        let qtd = document.getElementById('qtd');

        if (!produto || !tipo_tecido || !cor || !tamanhos || !qtd)
            return;

        setLoad(true)
        const idToast = toast.loading("Aguarde um pouco...")

        let data = {
            produto: produto.value,
            tipo_tecido: tipo_tecido.value,
            tamanho: tamanhos.value,
            cor: cor.value,
            qtd: qtd.value
        };

        requesAmin("/admin/produto/estoque/inserir", "POST", data, false).then((res) => {
            toast.dismiss(idToast);
            res.erro ? toast.error(res.msg) : toast.success(res.data.msg);
            setLoad(false)
            setModif(Math.random(9999))
        })
    }

    const removerEstoque = (e) => {

        let produto = document.getElementById('produto');
        let tipo_tecido = document.getElementById('tipo_tecido');
        let tamanhos = document.getElementById('tamanhos');
        let cor = document.getElementById('cores');
        let qtd = document.getElementById('qtd');

        if (!produto || !tipo_tecido || !cor || !tamanhos || !qtd)
            return;

        setLoad(true)
        const idToast = toast.loading("Aguarde um pouco...")

        let data = {
            produto: produto.value,
            tipo_tecido: tipo_tecido.value,
            tamanho: tamanhos.value,
            cor: cor.value,
            qtd: qtd.value
        };

        requesAmin("/admin/produto/estoque/remover", "POST", data, false).then((res) => {
            toast.dismiss(idToast);
            res.erro ? toast.error(res.msg) : toast.success(res.data.msg);
            setLoad(false)
            setModif(Math.random(9999))
        })
    }

    return (<>
        <MenuLateral conteudo={
            <div className="laterais altura-tela">
                <TituloSeparacao texto={"Lançamento de Estoque"} />
                <TopoEstoque inserirEstoque={inserirEstoque} removerEstoque={removerEstoque} load={load} />

                <TituloSeparacao texto={"Listagem de Estoque"} />
                <div className="row box-lancamento-vendas" >
                    <div className="col s12">
                        <table class="responsive-table striped centered">
                            <thead>
                                <tr>
                                    <th>PRODUTO</th>
                                    <th>TIPO</th>
                                    <th>COR</th>
                                    <th>TAMANHO</th>
                                    <th>ORIGINAL</th>
                                    <th>ATUAL</th>
                                    <th>FOTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estoque &&
                                    estoque.map((item) => {
                                        const url = `https://storage.googleapis.com/loja-move-on/${item.REF}/1.JPEG`
                                        return <tr className='text-table' id={item.ID} >
                                            <td>{item.NOME}</td>
                                            <td>{item.TIPO_TECIDO} </td>
                                            {/* <td>{item.TIPO_TECIDO} {item.TIPO_TECIDO != item.DESCRICAO ? <div>{item.DESCRICAO}</div> : ''}</td> */}
                                            <td>{item.COR}</td>
                                            <td>{item.TAMANHO}</td>
                                            <td>{item.ESTOQUE_ORIGINAL}</td>
                                            <td>{item.ESTOQUE_ATUAL}</td>
                                            <td><ModalZoomImage id={item.REF} /></td>

                                            {/* <td><img alt="img produto" src={url} className="img-listagem-produtos materialboxed" /></td> */}
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>

                    </div >
                </div >

            </div >
        } />
    </>);
};